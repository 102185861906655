<template>
    <layout-full-width :title="$tc('trash-bin', 1)">
        <component-actions :action-filters="actionFilters"
                           :bulk="bulk"
                           :extended-search="extendedSearch"
                           :general-search-fields="generalSearchFields"
                           :itemsPerPage="itemsPerPage"
                           :page="page"
                           :total="total"
                           @applyFilters="onApplyFilters"
                           @changeItemsPerPage="onChangeItemsPerPage"
                           @paginate="onChangePage"
                           @refresh="listCampaigns"/>
        <div class="row">
            <div class="col-12">
                <div class="table-responsive pb-5">
                    <table :class="{'table-hover': !loading.CAMPAIGN_LIST && campaigns.total !== 0 }" class="table">
                        <thead>
                        <component-table-header :heading-columns="headingColumns"
                                                :select-all-status="getSelectAllStatus()"
                                                :show-checkbox="true"
                                                @sort="onSortCampaigns"
                                                @toggleCheckbox="toggleCheckbox"/>
                        </thead>
                        <tbody>
                        <template v-cloak v-if="!loading.CAMPAIGN_LIST && campaigns.list.length > 0">
                            <tr v-for="(campaign, i) in campaigns.list"
                                :key="campaign.id"
                                class="pointer">
                                <td class="align-middle">
                                    <button class="btn btn-checkbox" @click="toggleCheckbox(i)">
                                        <font-awesome-icon
                                            :class="campaign.selected ? 'text-primary' : ''"
                                            :icon="campaign.selected ? ['fas', 'check-square'] : ['far', 'square']"
                                            class="pe-none"/>
                                    </button>
                                </td>
                                <td class="align-middle">
                                    <span>{{ campaign.campaignDescShort }}</span>
                                </td>
                                <td class="align-middle">
                                    {{ $tc('campaign', 1) }}
                                </td>
                                <td class="align-middle">
                                    {{ campaign.metaUpdateDt | timestamp }}
                                </td>
                                <td class="align-middle">
                                    <div class="dropdown">
                                        <button id="dropdownMenuButton"
                                                aria-expanded="false"
                                                aria-haspopup="true"
                                                class="btn p-0 bg-transparent"
                                                data-bs-toggle="dropdown"
                                                type="button">
                                            <font-awesome-icon class="pe-none" icon="ellipsis-h"/>
                                        </button>
                                        <div aria-labelledby="dropdownMenuButton"
                                             class="dropdown-menu dropdown-menu-end">
                                            <button class="dropdown-item">
                                                {{ $t('actions.restore') }}
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-cloak v-if="!loading.CAMPAIGN_LIST && campaigns.list.length === 0">
                            <tr>
                                <td colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('pages.trash-bin.no-items-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="loading.CAMPAIGN_LIST">
                            <tr v-for="n in itemsPerPage" :key="'row-shimmer-' + n">
                                <td class="align-middle">
                                    <component-shimmer height="14px" width="14px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="20px"/>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentActions from '@/components/ui/ComponentActions.vue';
import ComponentShimmer from "@/components/ComponentShimmer";
import ComponentTableHeader from '@/components/ui/ComponentTableHeader.vue';

export default {
    name: "PageTrashBin",
    components: {
        ComponentShimmer,
        LayoutFullWidth,
        ComponentActions,
        ComponentTableHeader
    },
    data() {
        return {
            filter: {
                "genStatus.gen_status_desc_short": "CAMPAIGN_TRASHED"
            },
            order: {meta_insert_dt: "DESC"},
            page: 1,
            itemsPerPage: 10,
            limit: null,
            total: 0,
            bulk: false,
            actionFilters: [
                {
                    title: this.$tc('type', 1),
                    key: 'genType.gen_type_desc_short',
                    values: [
                        {
                            name: this.$t('types.campaign.CAMPAIGN_REGULAR'),
                            value: 'CAMPAIGN_REGULAR'
                        },
                        {
                            name: this.$t('types.campaign.CAMPAIGN_AUTOMATED_EVENT_BASED'),
                            value: 'CAMPAIGN_AUTOMATED_EVENT_BASED'
                        },
                        {
                            name: this.$t('types.campaign.CAMPAIGN_MBOX'),
                            value: 'CAMPAIGN_MBOX'
                        }
                    ]
                }
            ],
            generalSearchFields: ['gen_campaign_desc_long'],
            extendedSearch: [
                {
                    name: this.$tc('name', 1),
                    technicalName: 'gen_campaign_desc_long'
                }
            ],
            headingColumns: [
                {
                    title: this.$tc('name', 1),
                    sortField: 'gen_campaign_desc_short'
                },
                {
                    title: this.$tc('object-type', 1)
                },
                {
                    title: this.$tc('date-of-deletion', 1)
                },
                {
                    title: ""
                }
            ],
            renameCampaignModal: {
                campaignUniqueId: null,
                campaignDescShort: '',
                campaignDescLong: null,
                campaignComment: null,
                campaignType: null,
                campaignStatus: null
            },
            update: {
                active: false
            }
        }
    },
    computed: {
        loading() {
            return {
                CAMPAIGN_LIST: this.$store.state.loading.CAMPAIGN_LIST
            }
        },
        campaign() {
            return this.$store.state.CAMPAIGN.campaign;
        },
        campaigns() {
            return this.$store.state.CAMPAIGN.campaigns;
        }
    },
    methods: {
        onChangePage(page) {
            if (page <= 0) {
                return;
            }

            this.page = parseInt(page, 10);
            this.listCampaigns();
        },
        onChangeItemsPerPage(itemsPerPage) {
            if (this.itemsPerPage === itemsPerPage) {
                return;
            }

            this.page = 1;
            this.itemsPerPage = itemsPerPage;
            this.listCampaigns();
        },
        onApplyFilters(filters) {
            this.filter = filters;
            this.listCampaigns();
        },
        onSortCampaigns(order) {
            this.order = order;
            this.listCampaigns();
        },
        listCampaigns() {
            this.$store.dispatch('CAMPAIGN/LIST', {
                filter: this.filter,
                order: this.order,
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                limit: this.limit,
            })
                .then(campaigns => {
                    this.total = campaigns.total;
                    this.getActionButton();
                });
        },
        async toggleCheckbox(i) {
            if (i === 'ALL') {
                const getSelectAllStatus = this.getSelectAllStatus();
                this.campaigns.list.forEach(campaign => campaign.selected = !getSelectAllStatus);
            } else {
                this.campaigns.list[i].selected = !this.campaigns.list[i].selected;
            }

            this.$forceUpdate();
            this.getActionButton();
        },
        getSelectAllStatus() {
            const campaignCount = this.campaigns.list.length;
            let selectedCampaignCount = 0;

            this.campaigns.list.forEach(campaign => {
                if (campaign.selected) {
                    selectedCampaignCount += 1;
                }
            });

            return campaignCount === selectedCampaignCount && campaignCount !== 0;
        },
        getActionButton() {
            let selectedCampaignCount = 0;

            this.campaigns.list.forEach(campaign => {
                if (campaign.selected) {
                    selectedCampaignCount += 1;
                }
            })

            this.bulk = selectedCampaignCount !== 0;
        },
        getCampaignTypeClass(campaignType) {
            return {
                'bg-light': campaignType === 'CAMPAIGN_REGULAR',
                'bg-cyan text-dark': campaignType === 'CAMPAIGN_AUTOMATED_EVENT_BASED',
                'bg-indigo': campaignType === 'CAMPAIGN_MBOX'
            }
        },
        getCampaignTypeLabel(campaignType) {
            if (this.$te('types.campaign.' + campaignType, this.$i18n.locale)) {
                return this.$t('types.campaign.' + campaignType)
            }

            return this.$t('unknown');
        },
        onArchiveCampaign(campaign) {
            const payload = {
                campaignUniqueId: campaign.campaignUniqueId,
                campaignDescShort: campaign.campaignDescShort,
                campaignDescLong: campaign.campaignDescLong,
                campaignComment: campaign.campaignComment,
                campaignType: campaign.campaignType,
                campaignStatus: 'CAMPAIGN_ARCHIVED',
                mboxEmail: null,
                channels: [{channelUniqueId: campaign.channels[0].channelUniqueId}]
            };

            if (Object.prototype.hasOwnProperty.call(campaign, 'mboxEmail')
                && campaign.mboxEmail !== undefined
            ) {
                payload.mboxEmail = campaign.mboxEmail;
            }

            this.$store.dispatch('CAMPAIGN/UPDATE', payload)
                .then(() => {
                    campaign.campaignStatus = 'CAMPAIGN_ARCHIVED';
                    this.$store.dispatch('toast/success', {
                        title: this.$tc('toast.success.success'),
                        message: this.$tc('toast.success.archive-campaign'),
                    });
                    this.$gtag.event('CAMPAIGN_UPDATE');
                });
        },
    },
    beforeMount() {
        this.listCampaigns();
    }
}
</script>
<style lang="scss" scoped>
</style>
